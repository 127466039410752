import React from 'react'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'
import { useUserStore } from 'services/user.service'

// TODO: fix typescript
// ez nem igazán HOC https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
function PrivateRoute <P extends object>(props: RouteProps) {
  const userStore = useUserStore()
  let location = useLocation();
  
  if(!userStore.isAuthenticated ) {
    userStore.setRedirect(location.pathname || "/")
  }

  return userStore.isAuthenticated 
    ?
      <Route {...props} />
    : <Redirect to={{
          pathname: '/auth/login',
          state: { from: props.location }
        }} />
}

export default PrivateRoute
