import React from 'react'
import List from '@material-ui/core/List';
import ListItemLink from 'Components/ListItemLink'
import {LogType} from '../../types'
import { useDataService } from 'services/firebase.service';
import { setBreadcrumb } from 'Components/Breadcrumb';

export default function Home({callback}: {callback: Function} ) {
  const DataService = useDataService()
  setBreadcrumb('')
  
  return (<List>
    <ListItemLink to='/registry' primary='Jegyzőkönyvíró' onClick={() => {
      DataService.logType = LogType.Registry;
      callback();
    }} />
    <ListItemLink to='/registry' primary='Kommentátor' onClick={() => {
      DataService.logType = LogType.Comment;
      callback();
    }} />
    <ListItemLink to='/view' primary='Nézőke'  onClick={callback}/>
    <ListItemLink to='/stats' primary='Statisztikák'  onClick={callback}/>
    <ListItemLink to='/admin' primary='Admin'  onClick={callback}/>
  </List>)
}
Home.defaultProps = {
  callback: () => {}
}