import React, { useCallback, useState } from 'react'
import {Log, Actions, LogState, Match} from '../../types'
import { useFeedback } from 'Components/Feedback';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { useDataService, DataService } from 'services/firebase.service';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import GetMatchResults from '../../utils/get-match-results';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      marginBottom: 10,
    },
    dismissed: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      marginBottom: 10,
      backgroundColor: 'lightgrey'
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    popover: {
      padding: theme.spacing(2),
    },
  })
);

function formatTime(millis: number, matchLength: number) {
  millis = matchLength - millis
  const seconds = `0${Math.floor(millis / 1000) % 60}`
  const minutes = `0${Math.floor(millis / 1000 / 60)}`
  return `${minutes.slice(-2)}:${seconds.slice(-2)}`
}

function renderComment({log}: {log: Log}) {
  return <p>{log.comment}</p>
}

function renderStart({log}: {log: Log}) {
  return <Grid item xs={6}>
              <span>Játék indítás</span>
          </Grid>
}

function renderUnknown({log}: {log: Log}) {
  return (
    <Grid item xs={12} sm container>
      <Grid item xs container spacing={2}>
        <Grid item xs>
        {log.player} ({log.team?.name})
        </Grid>
        <Grid item xs>
          {Actions[log.action as Actions]}
        </Grid>
      </Grid>
    </Grid>
  )
}

function renderWithResult({log, logs, matchData}: {log: Log, logs: Log[], matchData: Match}) {
  const DataService = useDataService()
  let currentResult = null
  if(logs) {
    const results = GetMatchResults(DataService, matchData, logs.filter(l => l.created <= log.created))
    currentResult = (<Grid item xs>
      Aktuális állás<br />
      {results.home} : {results.visitor}
    </Grid>)
  }
  return (
    <Grid item xs={12} sm container>
      <Grid item xs container spacing={2}>
        <Grid item xs>
        {log.player} ({log.team?.name})
        </Grid>
        <Grid item xs>
          {Actions[log.action as Actions]}
        </Grid>
        {currentResult}
      </Grid>
    </Grid>
  )
}

function getRenderer(action: number) {
  switch(action) {
    case Actions.Summary:
      return renderComment;
    case Actions.Start:
      return renderStart;
    case Actions.Action:
    case Actions.PenaltySuccess:
    case Actions.Center:
    case Actions.Sniper:
    case Actions.Deadeye:
    case Actions.QualificationScore:
      return renderWithResult;
    default:
      return renderUnknown 
  }
}

export default function Log({log, matchId, action, matchLength, matchData, logs}: {log: Log, logs?: Log[], matchId: string, matchData: Match, action?: 'delete' | 'setState', matchLength: number}) {
  const classes = useStyles();
  const [removed, remove] = useState<boolean>(false)
  const [logDismissed, setStatus] = useState<boolean>(log.status === LogState.Dismissed)
  const DataService = useDataService()
  const Renderer = getRenderer(log.action as Actions);
  const setFeedback = useFeedback()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const deleteLog = useCallback(() => {
    DataService.deleteLog(matchId, log.reference)
      .then(() => {
          setFeedback('Törölve', 'default')
          remove(true)
      })
  }, [])

  const showActions = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [])

  const hideActions = useCallback(() => setAnchorEl(null), [])

  const handleChange = useCallback(() => {
    DataService.setLogStatus(matchId, log.reference, log.status === LogState.Dismissed ? LogState.Final : LogState.Dismissed)
      .then(() => {
          setFeedback('Módosítás mentve', 'default')
          setStatus(prevStatus => !prevStatus)
      })
  }, [logDismissed])

  let renderAction = null
  switch(action) {
    case 'delete':
      renderAction = (<Grid item>
        <IconButton onClick={deleteLog}>
          <DeleteForeverIcon />
        </IconButton>
      </Grid>)
      break
    case 'setState':
      renderAction = (<Grid item>
        <IconButton onClick={showActions}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={hideActions}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <FormControlLabel
            className={classes.popover}
            control={<Switch checked={logDismissed} onChange={handleChange} />}
            label="Elrejt"
          />
        </Popover>
      </Grid>)
      break
    default:
      renderAction = null
  }

  if(removed) {
    return null
  }

  return (
  <Paper className={logDismissed ? classes.dismissed : classes.paper} elevation={3}>
      <Grid container spacing={2} justify="space-between">
        <Grid item>
          {formatTime(log.relativeTime, matchLength)}
        </Grid>
        {React.createElement(Renderer, {log, logs, matchData})}
        {renderAction}
      </Grid>
    </Paper>
  )
}
