import React, {useState, useCallback} from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const FeedbackContext = React.createContext<(message: string, type: string) => void>()

export default function Feedback(props) {
  const [feedback, setFeedback] = useState({
    show: false,
    message: '',
    type: 'default'
  })

  const feedbackHandler = useCallback((message:string, type: string) => setFeedback({
    show: true,
    message,
    type
  }), [])

  const handleClose = useCallback(() => setFeedback({
    ...feedback,
    show: false
  }), [])

  return (
    <FeedbackContext.Provider value={feedbackHandler}>
      {props.children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={feedback.show}
        autoHideDuration={1500}
        onClose={handleClose}
        message={feedback.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </FeedbackContext.Provider>
  )
}

export function useFeedback () {
  const context = React.useContext(FeedbackContext)
  if (!context) {
    throw new Error(`useFeedback must be used within a FeedbackContext`)
  }
  return context
}
