import {CountByPlayer} from './count-by-player'
import {Match, Log} from '../types'
import {DataService} from 'services/firebase.service'

export interface Summary {
  home: number,
  visitor: number,
  Q?: {
    home: number,
    visitor: number
  },
  type: 'Basic' | 'Extra' | 'Super' | 'Ultimate' | 'Ismeretlen' | null,
  length: number
}

export default function GetMatchResults(DataService: DataService, matchData: Match, logs: Log[]): Summary {
  let summary: Summary = {
    home: 0,
    visitor: 0,
    Q: {
      home: 0,
      visitor: 0
    },
    type: null,
    length: 0
  }

  matchData.players.forEach((player, idx) => {
    let team: 'home'|'visitor' = 'home'
    if(DataService.getTeamForPlayer(player).reference === matchData.visitor) {
      team = 'visitor'
    }

    [0, 10, 14, 1, 2, 3].forEach((action) => {
      const count = CountByPlayer(logs, player, action)
      switch(action) {
        // Action, QS, Penalty
        case 10:  // QS
          summary.Q[team] += count
        case 0:
        case 14:
          summary[team] += count
          break
        // Center, Sniper
        case 1:
        case 2:
          summary[team] += count * 2
          break
        // Deadeye
        case 3:
          summary[team] += count * 4
          break
      }
    })
  })
  const q = Math.max(summary.Q.home, summary.Q.visitor)
  switch(true) {
    case (q <= 3):
      summary.type = 'Basic'
      summary.length = 10*60*1000
      break
    case (q == 4):
      summary.type = 'Extra'
      summary.length = 12*60*1000
      break
    case (q == 5):
      summary.type = 'Super'
      summary.length = 15*60*1000
      break
    case (q == 6):
      summary.type = 'Ultimate'
      summary.length = 20*60*1000
      break
    default:
      summary.type = 'Ismeretlen'
      summary.length = 0
  }
  return summary
}