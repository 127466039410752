import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TeamLogo from 'Components/TeamLogo'
import { Match } from '../../types'
import { useDataService } from 'services/firebase.service'

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    logo: {
      width: 50,
      borderRadius: 50,
    },
    team: {
      position: "relative",
      top: -20,
      width: 160,
      display: "inline-block",
      fontWeight: "bold",
    },
    homeTeam: {
      textAlign: "right",
      paddingRight: "1em",
    },
    visitorTeam: {
      textAlign: "left",
      paddingLeft: "1em",
    },
    vs: {
      position: "relative",
      top: -15,
      fontSize: "2em",
    },
  },)
);

export default function TeamHeader ( {matchData}: { matchData: Match}) {
    const classes = useStyles();
    const DataService = useDataService()

    return <div>
        <h2>{matchData.order}</h2>
        <TeamLogo teamname={matchData.home} className={classes.logo}/>
        <span className={`${classes.team} ${classes.homeTeam}`} > { DataService.getTeam(matchData.home).name }</span>
        <span className={classes.vs}> VS </span>
        <span className={`${classes.team} ${classes.visitorTeam}`}>{ DataService.getTeam(matchData.visitor).name }</span>
        <TeamLogo teamname={matchData.visitor} className={classes.logo}/>
    </div>
}