import {observable, action, runInAction, configure, computed} from 'mobx'
import * as firebase from 'firebase/app'; 
import * as React from 'react'

class User {
  @observable user: firebase.User | null = null
  redirectTo: string = '/'

  @action onAuthenticate(user: firebase.User | null) {
    this.user = user
  }

  @action onLogout() {
    this.user = null
  }

  @computed get isAuthenticated(): boolean {
    return !!this.user
  }

  setRedirect(pathname: string) {
    this.redirectTo = pathname
  }
}

const UserStore = new User()

const UserContext = React.createContext<User>(UserStore)

// Mivel a UserStore egy singleton, ezért a createContext-ben definiáljuk
// function ComposerProvider ({ ...props }) {
//   const CurrentUser = useLocalStore(() => new User())
//   return <UserContext.Provider value={CurrentUser} {...props} />
// }

function useUserStore () : User {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error(`useUserStore must be used within a UserContext`)
  }
  return context
}

export default UserStore
export { useUserStore }
