import React, { Component, ErrorInfo } from 'react'
import { SentryContext, Sentry } from 'services/sentry'
import Button from '@material-ui/core/Button'
import { Scope } from '@sentry/browser'

interface ErrorBoundaryProps {
  sentry: Sentry;
}

interface ErrorBoundaryState {
  error: Error | null;
  eventId: string | undefined;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor (props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null, eventId: undefined }
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    this.setState({ error })
    if (process.env.NODE_ENV === 'development') {
      console.error(error, errorInfo)
    } else {
      this.props.sentry.withScope((scope: Scope) => {
        scope.setExtras(errorInfo)
        const eventId = this.props.sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render () {
    if (this.state.error) {
      // render fallback UI
      return (
        <Button onClick={() => this.props.sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
      )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

function ContextError (props: object) {
  return (
    <SentryContext.Consumer>
      {sentry => (
        <ErrorBoundary sentry={sentry} {...props} />
      )}
    </SentryContext.Consumer>
  )
}

export default ContextError
