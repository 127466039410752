import React, { useEffect, useCallback } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Observer } from 'mobx-react-lite';
import * as firebase from 'firebase/app'; 
import {useUserStore} from 'services/user.service'
import {Logo} from '../../assets'
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import { useFeedback } from 'Components/Feedback';
import Breadcrumb from 'Components/Breadcrumb';
import Home from './Home';
import ChampionshipPicker from './ChampionshipPicker';
 
const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    appBar: {
      backgroundColor: 'white',
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    logo: {
      padding: 10,
    },
    title: {
      color: 'black'
    },
    drawerPaper: {
      width: drawerWidth,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: 'black',
      position: "absolute",
      right: 0,
    },
    centeredMenuItem: {
      textAlign: 'center',
    },
    userMenuItem: {
      textAlign: 'center',
      width: '100%',

    },
    userImage: {
      display: 'inline-block',
    },
    userName: {
      fontWeight: 'bold',
      fontSize: '1.1em',
    },
  },)
);

export default function Menu () {
  const userStore = useUserStore()
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const setFeedback = useFeedback();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    firebase.auth().signOut().then(() => {
      handleDrawerToggle();
      userStore.onLogout()
      history.push('/auth/login');
      setFeedback(`Sikeres kijelentkezés`, 'default');
    });
  };

  const drawer = (<Paper elevation={0}>
    <Observer>
      {() => userStore.isAuthenticated
        ? (<React.Fragment>
          <ListItem>
            <div className={classes.userMenuItem} >
              <div className={classes.userImage}><Avatar src={userStore.user?.photoURL as string}></Avatar></div>
              <div className={classes.userName}>{userStore.user?.displayName}</div>
              <div>{userStore.user?.email}</div>
            </div>
          </ListItem>
          <Divider />
          <Home callback={handleDrawerToggle} />
          <Divider />
          <ListItem button onClick={logout} className={classes.centeredMenuItem}>
            <ListItemText>Kijelentkezés</ListItemText>
          </ListItem>
          </React.Fragment>)
        : <span/>
        }
    </Observer>

    <Divider />
  </Paper>)

  const container = window !== undefined ? () => window.document.body : undefined;

  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
  return (<React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link to="/"className={classes.logo}>
              <img src={Logo}/>
          </Link>
          <Breadcrumb />
          <Observer>
          {() => userStore.isAuthenticated? 
            <React.Fragment>
              <ChampionshipPicker />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </React.Fragment>
            :
            <span/>
            }
          </Observer>
        </Toolbar>
      </AppBar>

      <nav aria-label="mailbox folders">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            anchor="right"
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
      </nav>
    </React.Fragment>
  )
}