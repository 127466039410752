import React from 'react'
import {Match} from '../../types'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemLink from 'Components/ListItemLink'
import { useDataService } from 'services/firebase.service'

export default function MatchList({url, matches}: {url: string, matches: Match[]}) {
  const DataService = useDataService()

  return matches.length === 0
      ? (<List>
          <ListItemText primary='Nincsenek mérkőzések'></ListItemText>
        </List>)
      : (<List>
          {matches.map(match => (
            <ListItemLink key={match.reference} to={`${url}/${match.reference}`} primary={`${match.order} - ${DataService.getTeam(match.home).name} - ${DataService.getTeam(match.visitor).name}`} />
          ))}
        </List>)
}