import React from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'Components/PrivateRoute'
import Auth from '../Auth'
import NotFoundPage from './NotFoundPage'
import LazyComponent from 'Components/LazyHOC'
import Menu from './Menu';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { useApplication } from 'services/application.service';
import { observer } from 'mobx-react-lite';
import { BreadcrumbProvider } from 'Components/Breadcrumb';

const Home = LazyComponent(() => import('./Home'));
const Admin = LazyComponent(() => import('../Admin'));
const Registry = LazyComponent(() => import('../Registry'));
const View = LazyComponent(() => import('../View'));
const Stats = LazyComponent(() => import('../Stats'));

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      display: 'flex',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);
      
function ListRouter() {
  const classes = useStyles();
  const Application = useApplication()
  
  return (
    <div className={classes.root}>
      <CssBaseline/>
        <BreadcrumbProvider>
        <Menu></Menu>
        <main className={classes.content}>
          <div className={classes.toolbar}>WSB</div>
          <Paper className={classes.paper}>
            {Application.hasNewVersion
              ? (<Grid container>
                <Grid item xs={12}><Alert severity="warning" action={
                  <Button color="inherit" size="small" onClick={() => Application.reloadPage()}>Töltsd újra az oldalt</Button>
                }>Új verzió érhető el</Alert></Grid>
              </Grid>)
              : null
            }
            <Switch>
              <Route path="/auth" component={Auth} />
              <PrivateRoute path="/admin" component={Admin} />
              <PrivateRoute path="/registry" component={Registry} />
              <PrivateRoute path="/view" component={View} />
              <PrivateRoute path="/stats" component={Stats} />
              <PrivateRoute exact path="/" component={Home} />
              <Route component={NotFoundPage} />
            </Switch>
          </Paper>
        </main>
      </BreadcrumbProvider>
    </div>
  );
}

export default observer(ListRouter)