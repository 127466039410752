import React from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useUserStore } from 'services/user.service';
import { Observer } from 'mobx-react-lite';
import * as firebase from 'firebase/app'; 

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
  },
  loginButton: {
    display: 'inline-block',
  },
});

function Login({history}: RouteComponentProps) {
  const userStore = useUserStore()
  const classes = useStyles();

  return (
    <Observer>
    {() => userStore.isAuthenticated ? <p>Be vagy jelentkezve</p> : 
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="body2" component="p">
            Az oldal használatához kérlek jelentkezz be!
          </Typography>
        </CardContent>
        <CardActions className={classes.loginButton}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </CardActions>
      </Card>
    }
    </Observer>
  );
}

export default Login
