import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
// import * as log from '@fontanus/logger'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(XHR)
  .init({
    // lng: 'hu',
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    defaultNS: 'translation',
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: process.env.NODE_ENV === 'development' ? 'dev' : 'en',
    interpolation: {
      escapeValue: false
    }
  }, (err, t) => {
    if (err) {
      // log.error('i18n init error', err)
      return
    }
  })
  
export default i18n
