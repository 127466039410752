import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useDataService } from 'services/firebase.service';
import Button from '@material-ui/core/Button';
import { observer } from 'mobx-react-lite';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Championship} from '../../types'
import { useHistory } from 'react-router-dom';

const emails = ['username@gmail.com', 'user02@gmail.com'];

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    title: {
      color: 'black'
    },
  },)
);

interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function Picker({onClose, selectedValue, open}: SimpleDialogProps) {
  const DataService = useDataService()
  const [championships, setChampionships] = useState<Championship[]>([])

  useEffect(() => {
    DataService.championships.get().then((querySnapshot) => {
      const championships: Championship[] = []
      querySnapshot.forEach((doc) => {
        championships.push({
          reference: doc.id,
          ...doc.data()
        } as Championship)
      });
      setChampionships(championships)
    })
  }, [])

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };
  
  return (<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
  <DialogTitle id="simple-dialog-title">Válassz bajnokságot</DialogTitle>
  <List>
    {championships.map((championship) => (
      <ListItem button onClick={() => handleListItemClick(championship.reference)} key={championship.reference}>
        <ListItemText primary={championship.name} />
      </ListItem>
    ))}
  </List>
</Dialog>)
}

function ChampionshipPicker() {
  const [open, setOpen] = React.useState(false);
  const DataService = useDataService()
  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    DataService.currentChampionshipName = value
    history.push('/')
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>{DataService.currentChamptionshipData?.name} ({DataService.currentChamptionshipData?.year})</Button>
      <Picker onClose={handleClose} open={open} selectedValue={''} />
    </div>
  )
}

export default observer(ChampionshipPicker)