import * as firebase from 'firebase/app'; 
interface Document {
  reference: string
}

export interface Team extends Document {
  name: string,
  players: number[]
}

export enum Actions {
  Action, 
  Center, 
  Sniper, 
  Deadeye, 
  Goalkeeper, 
  GoalkeeperInterception, 
  Interception, 
  Block, 
  Penalty,
  Timeout, 
  QualificationScore, 
  QualificationFailed, 
  Start, 
  Summary,
  PenaltySuccess,
  PenaltyFailed,
  ReStart,
  Profanity
}

export enum LogState {
  Draft, Final, Dismissed
}

export enum LogType {
  Comment, Registry
}

export interface PartialLog {
  player?: number,
  action?: Actions,
  comment?: string,
  created?: firebase.firestore.Timestamp,
}

export interface Log  extends Document, PartialLog {
  type: LogType,
  user: string,
  created: firebase.firestore.Timestamp,
  team?: {
    name: string,
    reference: string
  },
  status: LogState,
  relativeTime: number  // milliseconds since Actions.Start
}

export interface Match extends Document {
  created?: string,
  home: string,
  visitor: string,
  order?: number,
  players: number[],
  logs: Log[]
}

export interface Championship extends Document {
  name: string,
  year: number,
  teams: firebase.firestore.DocumentReference[]
  matches: Match[]
}