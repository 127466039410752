import React, { useContext, useState, SetStateAction } from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

type SimpleBreadcrumb = {
  text: string,
  setText: (value:string) => undefined
}

export const BreadcrumbContext = React.createContext<SimpleBreadcrumb|null>(null)

export function BreadcrumbProvider(props) {
  const [text, setText] = useState('')
  return (<BreadcrumbContext.Provider value={{text, setText}}>{props.children}</BreadcrumbContext.Provider>)
}

export default function Breadcrumb() {
  const breadcrumb = useBreadcrump()
  return (<Breadcrumbs aria-label="breadcrumb">  
  <Typography color="textPrimary">{breadcrumb}</Typography>
</Breadcrumbs>)
}

export function useBreadcrump() {
  const context = useContext(BreadcrumbContext)
  if (!context) {
    throw new Error(`useUserStore must be used within a UserContext`)
  }
  return context.text
}

export function setBreadcrumb(value: string) {
  const context = useContext(BreadcrumbContext)
  if (!context) {
    throw new Error(`useUserStore must be used within a UserContext`)
  }
  context.setText(value)
}