import React, { useState, useEffect } from 'react'
import { Log as LogType, Match, Actions } from '../../types'
import GetMatchResults, {Summary} from '../../utils/get-match-results'
import { useDataService } from 'services/firebase.service'
import TeamHeader from 'Components/TeamsHeader'

export default function MatchSummary({matchId, logs}: {matchId: string, logs: LogType[]}) {
  const [matchData, setMatch] = useState<Match>()
  const [summary, setSummary] = useState<Summary>({
    home: 0,
    visitor: 0,
    type: null,
    length: 0
  })
  const DataService = useDataService()

  useEffect(() => {
    DataService.currentChampionship.collection('matches').doc(matchId).get().then((doc) => {
      setMatch({
        reference: doc.id,
        ...doc.data()
      } as Match)
    })
  }, [])

  useEffect(() => {
    if(!matchData) return

    setSummary(GetMatchResults(DataService, matchData, logs))
  }, [matchData, logs.length])

  if(!matchData) return null

  return (<React.Fragment>
    <TeamHeader matchData={matchData} />
    <div>{summary.type}: {summary.home} - {summary.visitor}</div>
  </React.Fragment>)
}