import React, {useEffect} from 'react'
import {IntlProvider} from 'react-intl'
import language from './utils/detect-language'
import { createHashHistory as createHistory } from "history";
import { Router, useHistory } from "react-router";
import './i18n'
import './services/firebase.service'
import Error from 'Components/Error'
import Main from 'Containers/Main'
import Feedback from 'Components/Feedback'
import { useUserStore } from 'services/user.service';
import * as firebase from 'firebase/app'; 
import { useApplication } from 'services/application.service';
import { useDataService } from './services/firebase.service';
import { autorun } from 'mobx';

function App() {
  const userStore = useUserStore()
  const DataService = useDataService()
  const history = createHistory()
  const Application = useApplication()
  
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(
      (user) => {
        userStore.onAuthenticate(user)
        if(user != null) {
          autorun(DataService.updateChampionshipData.bind(DataService))
          DataService.getTeams()
            .then(() => {
              history.push(userStore.redirectTo)
            })
        }
      }
    );
  }, [])

  return (
    <Error>
      <Router history={history}>
        {Application.isReady
          ? (
            <IntlProvider locale={language} messages={{}}>
              <Feedback>
                <Main></Main>
              </Feedback>
            </IntlProvider>
          )
          : <p>Töltődök, pihi van ... </p>
        }
      </Router>
    </Error>
  );
}

export default App;
