import React, { Suspense } from 'react'

export default function Lazy(loader: () => Promise<{ default: React.ComponentType<any>; }>) {
  const LazyComponent = React.lazy(loader);

  return props => (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent {...props}></LazyComponent>
    </Suspense>)
}
