import React, { useContext } from 'react'
import { Workbox } from 'workbox-window'
import { observable, runInAction, computed } from 'mobx'
import * as configuration from '../config.yaml'

export interface Configuration {
  sentry: string,
  firebase: Object
}

export class Application {
  environment: string = process.env.NODE_ENV === 'development' ? 'local' : window.location.hostname.split('.')[0]
  build: string = process.env.BUILD_ID ? process.env.BUILD_ID : 'local'
  workbox: Workbox | null
  @observable hasNewVersion: boolean = false
  config: Configuration

  constructor (config: Configuration) {
    this.config = config
    this.workbox = this.startWorker('/service_worker.js', {})
  }

  startWorker (swFile: string, options: object) {
    if ('serviceWorker' in navigator) {
      const wb = new Workbox(swFile, options)

      wb.addEventListener('waiting', (event) => {
        console.log('waiting')
        runInAction(() => { this.hasNewVersion = true })
      })

      wb.register()
      return wb
    }
    return null
  }

  reloadPage () {
    if (this.workbox == null) {
      return window.location.reload()
    }

    return this.workbox.messageSW({ type: 'SKIP_WAITING' })
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        window.location.reload()
      })
  }

  @computed get isReady() {
    return typeof this.workbox != undefined
  }
}

const AppStore = new Application(configuration)

const AppContext = React.createContext<Application>(AppStore)

export function useApplication() {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error(`useUserStore must be used within a UserContext`)
  }
  return context
}

export default AppStore