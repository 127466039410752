import React, {useState, useEffect} from 'react'
import MatchList from 'Components/MatchList'
import {useRouteMatch} from 'react-router-dom'
import {Match} from '../../types'
import { useDataService } from 'services/firebase.service'

export default function MatchListView() {
  const {url} = useRouteMatch()
  const [matches, setMatches] = useState<Match[]>([])
  const DataService = useDataService()

  useEffect(() => {
    DataService.getMatches().then((matches) => {
      setMatches(matches)
    });
  }, [])

  return <MatchList matches={matches} url={url} />
}