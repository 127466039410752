import * as firebase from 'firebase/app'; 
import 'firebase/auth';
import 'firebase/firestore';
import App from './application.service'
import UserStore from './user.service'
import {Team, Log, PartialLog, LogType, LogState, Actions, Match, Championship} from '../types'
import React, { useContext } from 'react';
import { observable, computed, autorun, runInAction } from 'mobx';

export type Filter = [
  string | firebase.firestore.FieldPath,
  firebase.firestore.WhereFilterOp,
  any
]

firebase.initializeApp(App.config.firebase)

firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
        alert('Nem sikerült az offline módot bekapcsolni. Csak egy ablakban legyen a felület nytiva!')
      } else if (err.code == 'unimplemented') {
        console.warn('A böngésző nem támogatja az offline módot')
      }
  });

export class DataService {
  prefix: string = App.environment === 'local' ? 'test-' : ''
  teams: Team[] = []
  @observable currentChampionshipName: string = 'elit-2020'
  @observable currentChamptionshipData: Championship | null = null
  lastLogId: string = ''
  @observable logType: LogType = LogType.Registry
  matchStartTime: firebase.firestore.Timestamp | null = null

  get db() {
    return firebase.firestore();
  }

  get championships() {
    return this.db.collection(`${this.prefix}championships`)
  }

  @computed get currentChampionship() {
    return this.championships.doc(this.currentChampionshipName)
  }

  updateChampionshipData() {
    return this.currentChampionship.get().then(doc => {
      runInAction(() => {
        this.currentChamptionshipData = doc.data() as Championship
      })
    })
  }

  getTeams() {
    return this.db.collection("teams").get().then((querySnapshot) => {
      const teams: Team[] = []
      querySnapshot.forEach((doc) => {
        teams.push({
          reference: doc.id,
          ...doc.data()
        } as Team)
      });
      this.teams = teams
    });
  }

  getTeam(teamReference: string) {
    return this.teams.find(t => t.reference === teamReference) as Team
  }


  getTeamForPlayer(player: number): Team {
    return this.teams.find(t => t.players.includes(player as number)) as Team
  }

  async getMatches(matchIds: string[] = []) {
    return this.currentChampionship.collection('matches').orderBy('order', 'desc').get().then((querySnapshot) => {
      const matches: Match[] = []
      querySnapshot.forEach((doc) => {
        if(matchIds.length > 0 && !matchIds.includes(doc.id)) {
          return
        }
        
        matches.push({
          reference: doc.id,
          ...doc.data()
        } as Match)
      });
      return matches
    });
  }

  listenMatches(withMatches: (matches: Match[]) => void) {
    let ref:  firebase.firestore.Query = this.currentChampionship.collection('matches').orderBy('order', 'desc')
    return ref.onSnapshot(function(querySnapshot) {
      const matches: Match[] = []
      querySnapshot.forEach(function(doc) {
        matches.push({
            ...doc.data(),
            reference: doc.id,
          } as Match)
      });
      withMatches(matches)
    })
  }

  async saveLog(matchId: string, log: PartialLog) {
    if(!UserStore.user) return

    if(!log.created) {
      log.created = firebase.firestore.Timestamp.now()
    }

    if(log.action === Actions.Start) {
      this.matchStartTime = log.created
    } else if(log.action === Actions.ReStart && this.matchStartTime) {
      this.matchStartTime = firebase.firestore.Timestamp.fromMillis(this.matchStartTime?.toMillis() + parseFloat(log.comment as string))
    }

    const data: Log = {
      ...log,
      relativeTime: log.created.toMillis() - (this.matchStartTime != null ? this.matchStartTime.toMillis() : log.created.toMillis()),
      created: log.created,
      type: this.logType,
      user: UserStore.user.displayName as string,
      status: LogState.Draft,
      reference: `${log.created}-${UserStore.user.displayName}`
    }

    if(typeof log.player != 'undefined' && Number.isInteger(log.player)) {
      const team: Team = this.getTeamForPlayer(log.player)
      data.team = {
        name: team.name,
        reference: team.reference
      }
    }

    const dbPromise = this.currentChampionship.collection('matches').doc(matchId).collection('logs').doc(data.reference).set(data)
      .catch(function(err) {
        console.error(err)
      })
    if(window.navigator.onLine) {
      return dbPromise
    } else {
      return
    }
  }

  async getLog(matchId: string, ordering: 'desc' | 'asc' = 'asc', filters: Filter[] = []){
    let ref:  firebase.firestore.Query  = this.currentChampionship.collection('matches').doc(matchId).collection('logs')
    filters.forEach(([field, filterOperator, value]) => {
      ref = ref.where(field, filterOperator, value)
      if(['<', '<=', '>', '>='].includes(filterOperator)) {
        ref = ref.orderBy(field, 'asc')
      }
    })
    return ref.orderBy('created', ordering).get().then(function(querySnapshot) {
      const logs: Log[] = []
      querySnapshot.forEach(function(doc) {
          logs.push({
            ...doc.data(),
            reference: doc.id,
          } as Log)
      });
      return logs;
    })
  }

  listenLogs(matchId: string, withLogs: (logs: Log[]) => void, ordering: 'desc' | 'asc' = 'asc', filters: Filter[] = []) {
    let ref:  firebase.firestore.Query = this.currentChampionship.collection('matches').doc(matchId).collection('logs')
    filters.forEach(([field, filterOperator, value]) => {
      ref = ref.where(field, filterOperator, value)
      if(['<', '<=', '>', '>='].includes(filterOperator)) {
        ref = ref.orderBy(field, 'asc')
      }
    })
    return ref.orderBy('created', ordering).onSnapshot(function(querySnapshot) {
        const logs: Log[] = []
        querySnapshot.forEach(function(doc) {
            logs.push({
              ...doc.data(),
              reference: doc.id,
            } as Log)
        });
        withLogs(logs)
      })
  }

  async deleteLog(matchId: string, logId: string) {
    return this.currentChampionship.collection('matches').doc(matchId).collection('logs').doc(logId).delete()
      .catch((error) => {
        console.error('Failed deleting log', error)
      })
  }

  async setLogStatus(matchId: string, logId: string, status: LogState) {
    return this.currentChampionship.collection('matches').doc(matchId).collection('logs').doc(logId).update({
      status
    })
  }

  async deleteMatch(matchId: string) {
    const batch = this.db.batch()
    // delete all the logs  docs first to remove the logs collection
    const logsRef:  firebase.firestore.CollectionReference  = this.currentChampionship.collection('matches').doc(matchId).collection('logs')
    logsRef.get().then(querySnapshot => {
      querySnapshot.forEach(doc => batch.delete(doc.ref))
    })
    // delete the match document
    batch.delete(this.currentChampionship.collection('matches').doc(matchId))
    return batch.commit()
  }
}

const MyDataService = new DataService()

const DataContext = React.createContext<DataService>(MyDataService)

export function useDataService() {
  const context = useContext(DataContext)
  if (!context) {
    throw new Error(`useUserStore must be used within a UserContext`)
  }
  return context
}